import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useNavigation } from './useNavigation';
import Icon from '../icons/Icon';
import { refactorUrl } from './../helpers/url';
import logo from './../assets/images/KH_Word_white.png';
import { NavLink } from '../i18n/Link';

/**
 * Show the logo
 *
 * @param {object} props React props
 *
 * @returns {JSX.Element} The logo.
 */
function Logo(props) {
  return (
    <NavLink to="/" { ...props } >
      <img
        className="navigation-view__logo"
        src={ logo }
        alt="Kalkhoff"
      />
    </NavLink>
  );
}

/**
 * @param {string} navLinkUrl The url the navlink points to
 * @param {import('react-router').match} match Router match
 * @param {{
 *   pathname: string,
 * }} location Current browser location
 *
 * @returns {boolean} Match or not...
 */
function isActive(navLinkUrl, match, location) {
  if (!match) {
    return false;
  }

  if (navLinkUrl === '/') {
    return navLinkUrl === location.pathname;
  }

  return location.pathname.startsWith(navLinkUrl);
}

/**
 * @returns {JSX.Element} React component
 */
export default function NavigationView() {
  /** @type {import('../Kalkhoff').Navitem[]} */
  const navItems = useNavigation('primary');
  const showMoreItem = navItems?.length > 5;
  let menuItems = navItems;
  let moreItems = [];

  const [ showMoreItemsNav, setShowMoreItemsNav ] = useState(false);

  // If we are going to show the 'more' item in the navigation,
  // we have to split the array in two separate items.
  if (showMoreItem) {
    // menuItems contains the items that will always be shown.
    menuItems = navItems.slice(0, 4);
    // moreItems contains the items that will be shown when you click on 'more'.
    moreItems = navItems.slice(4);
  }

  // Make sure that users can close the menu...
  // using the escape key on the keyboard.
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        setShowMoreItemsNav(false);
      }
    };

    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <>
      <nav className="navigation-view">
        <div className="navigation-view__container">
          <Logo onClick={ () => setShowMoreItemsNav(false) } />
          <ul className="navigation-view__list">
            { menuItems.map((navitem, index) => (
              <NavLink
                className={ `navigation-view__item ${
                  showMoreItem && index > 3 ? 'hidden' : ''
                }` }
                key={ navitem.object_id }
                to={ refactorUrl(navitem.url) }
                isActive={ isActive.bind(null, refactorUrl(navitem.url)) }
                onClick={ () => setShowMoreItemsNav(false) }
              >
                { <Icon icon={ navitem.classes } /> }
                { navitem.title }
              </NavLink>
            )) }
            { showMoreItem && (
              <button
                className="navigation-view__item"
                onClick={ () => setShowMoreItemsNav(!showMoreItemsNav) }
              >
                <Icon icon="icon-dots" />
                <Trans i18nKey="more">
                  More
                </Trans>
              </button>
            ) }
          </ul>
        </div>
      </nav>
      { showMoreItemsNav && (
        <div className="more-items-navigation">
          <ul className="more-items-navigation__list">
            { moreItems.map((navitem) => (
              <NavLink
                className="more-items-navigation__list--item"
                key={ navitem.object_id }
                to={ refactorUrl(navitem.url) }
                onClick={ () => setShowMoreItemsNav(false) }
                isActive={ isActive.bind(null, refactorUrl(navitem.url)) }
              >
                { <Icon icon={ navitem.classes } /> }
                { navitem.title }
              </NavLink>
            )) }
          </ul>
        </div>
      ) }
    </>
  );
}
